import React, { useEffect, useRef, useState } from "react";
import
{
  BedRooms,
  serverAPI,
  ActivityType,
  buttonName,
  defaultCountry,
  validateEmail,
  formName,
  validatePhoneNumbers,
  validateName,
} from "../utils/envConfig";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { hasCookie, getCookie } from "cookies-next";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "./Loader";
import InputContact from "./Inputs/InputContact";
import InputEmail from "./Inputs/InputEmail";
import AgreeToTerms from "./AgreeToTerms";
import { fetchAmenities, fetchCommunities } from "../redux/actions/property";


const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 0,
  },
  {
    value: 300,
  },
  {
    value: 600,
  },
  {
    value: 900,
  },
  {
    value: 1200,
  },
  {
    value: 1500,
  },
];

const IOSSlider = withStyles( {
  root: {
    color: "#A6873B",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 32,
    width: 32,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 15px)",
    top: -22,
    "& *": {
      background: "#f00",
      color: "#fff",
    },
  },
  track: {
    height: 10,
  },
  rail: {
    height: 10,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 20,
    width: 4,
    marginTop: -4,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
} )( Slider );

const GetMatch = ( { showModal, setShowModal } ) =>
{
  const dispatch = useDispatch();
  const amenity = useSelector( ( state ) => state.property.amenities );
  const communities = useSelector( ( state ) => state.property.communities );
  const [ chipData, setChipData ] = useState( [] );
  const [ myAmenity, setMyAmenity ] = useState( [] );
  const [ amenityQuery, setAmenityQuery ] = useState( [] );
  const [ loggedIn, setLoggedIn ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ selectedCommunities, setSelectedCommunities ] = useState( [] );
  const [ name, setName ] = useState( "" );
  const [ email, setEmail ] = useState( "" );
  const [ phone, setPhone ] = useState( "" );
  const [ selectedCountry, setSelectedCountry ] = useState( defaultCountry );
  const [ submitting, setSubmitting ] = useState( false );
  const [ isAgreed, setIsAgreed ] = useState( true );
  const [ errors, setErrors ] = useState( {} );
  const handleErrors = ( name, err ) =>
  {
    setErrors( ( prev ) => ( {
      ...prev,
      [ name ]: err,
    } ) );
  };

  // const [ captchaVerified, setCaptchaVerified ] = useState( false );
  // const recaptcha = useRef( null );
  // const onCaptchaChange = async ( token ) =>
  // {
  //   if ( token )
  //   {
  //     const tokenTimestamp = Date.now();
  //     const tokenData = JSON.stringify( { token, tokenTimestamp } );
  //     try
  //     {
  //       const res = await axios.post( `${ serverAPI }/captcha/verify-captcha-token`, tokenData, {
  //         headers: { "Content-Type": "application/json" },
  //       } );
  //       if ( res?.data?.status === true )
  //       {
  //         setCaptchaVerified( true );
  //         handleErrors( "captcha", null || '' );
  //       } else
  //       {
  //         setCaptchaVerified( false );
  //         handleErrors( "captcha", "Error verifying captcha" );
  //       }
  //     } catch ( error )
  //     {
  //       setCaptchaVerified( false );
  //       handleErrors( "captcha", "Error verifying captcha", error );
  //     }
  //   } else
  //   {
  //     setCaptchaVerified( false );
  //     handleErrors( "captcha", "Captcha token is not available" );
  //   }
  // };

  const handleSubmit = async ( e ) =>
  {
    e.preventDefault();
    let isError = false;

    if ( !isAgreed )
    {
      isError = true;
      alert( "Please agree to terms and conditions" );
    }

    const isValidateName = validateName( name?.trim() );
    if ( isValidateName !== true || errors?.name )
    {
      isError = true;
      handleErrors(
        "name",
        errors?.name
          ? 'Enter Valid Name'
          : isValidateName
      );
    }

    const isValidEmail = validateEmail( email );
    if ( errors.email || !email.trim() || !isValidEmail )
    {
      isError = true;
      handleErrors(
        "email",
        errors?.email ?? !email.trim().length
          ? "Email is required"
          : "Invalid email"
      );
    }

    const isValidContact = validatePhoneNumbers( {
      cca2: selectedCountry.cca2,
      code: selectedCountry?.code,
      phone: phone,
    } );

    if ( errors.phone || !phone.trim() || !isValidContact )
    {
      isError = true;
      handleErrors(
        "phone",
        errors?.phone ?? !phone.trim().length
          ? "Phone is required"
          : "Invalid number for selected country"
      );
    }

    // if (!captchaVerified) {
    //   isError = true;
    //   handleErrors("captcha", "Please complete the captcha verification");
    // }else{
    //     handleErrors("captcha", null || '');
    // }

    if ( isError ) return;

    setSubmitting( true );
    let msg = {
    };
    if ( beds )
    {
      msg[ "Beds" ] = beds;
    }
    if ( minPrice )
    {
      msg[ "Minimun Budget" ] = minPrice + ' AED';
    }
    if ( maxPrice )
    {
      msg[ "Maximum Budget" ] = maxPrice + ' AED';
    }
    if ( myAmenity.length > 0 )
    {
      msg[ "Amenities" ] = myAmenity.map( ( ele ) => { ele.amenity; } );
    }
    if ( selectedCommunities.length > 0 )
    {
      msg[ "Communities" ] = selectedCommunities?.map( e => e.community );
    }
    if ( metroCheck )
    {
      msg[ "Metro/Bus Stop" ] = fromMetro + 'm';
    }

    if ( shopCheck )
    {
      msg[ "Shopping" ] = fromShopping + 'm';
    }

    if ( beachCheck )
    {
      msg[ "Beach" ] = fromBeach + 'm';
    }

    if ( schoolCheck )
    {
      msg[ "School" ] = fromSchool + 'm';
    }

    if ( mosqueCheck )
    {
      msg[ "Mosques" ] = fromMosques + 'm';
    }

    if ( superMarketCheck )
    {
      msg[ "SuperMarkets" ] = fromSuperMarket + 'm';
    }

    if ( pharmacyCheck )
    {
      msg[ "Pharmacy" ] = fromPharmacy + 'm';
    }

    if ( parkCheck )
    {
      msg[ "Park" ] = frompark + 'm';
    }

    let mySentence = '';
    for ( const key in msg )
    {
      mySentence += `${ key }: ${ msg[ key ] }\n`;
    }
    mySentence = mySentence.trim();

    const payload = JSON.stringify( {
      full_name: name,
      email,
      phone: `${ selectedCountry.code }${ phone }`,
      message: mySentence,
      type: ActivityType.GetMatchedAssistance,
      communities: selectedCommunities.map( e => e.slug ),
      building_distances: {
        "Metro/Bus Stop": metroCheck ? fromMetro : 0,
        "Shopping": metroCheck ? fromShopping : 0,
        "Beach": beachCheck ? fromBeach : 0,
        "School": schoolCheck ? fromSchool : 0,
        "Mosques": mosqueCheck ? fromMosques : 0,
        "SuperMarkets": superMarketCheck ? fromSuperMarket : 0,
        "Pharmacy": pharmacyCheck ? fromPharmacy : 0,
        "Park": parkCheck ? frompark : 0
      },
      communities: selectedCommunities?.map( e => e.community ),

    } );

    const headers = {
      "Content-Type": "application/json",
    };

    if ( hasCookie( "authuser" ) )
    {
      const { access_token } = JSON.parse( getCookie( "authuser" ) ) || "";
      headers[ "Authorization" ] = `Bearer ${ access_token }`;
    }

    try
    {
      const resp = await axios.post( `${ serverAPI }/contact`, payload, {
        headers,
      } );

      window.location.href = `/thank-you`;
    } catch ( err )
    {
      console.log( err );
    } finally
    {
      setSubmitting( false );
    }
  };
  useEffect( () =>
  {
    if ( hasCookie( "authuser" ) )
    {
      setLoggedIn( true );
      const { first_name, last_name, phone, email } = JSON.parse(
        getCookie( "authuser" )
      );

      setPhone( phone );
      setEmail( email );
      setName( `${ first_name } ${ last_name }` );
    }
    !communities?.length && dispatch( fetchCommunities() );
    !amenity?.length && dispatch( fetchAmenities() );
  }, [] );
  // essential modal configuration starts
  useEffect( () =>
  {
    let options = [];
    amenity.forEach( ( ele ) => options.push( { label: ele.amenity, key: ele.id } ) );
    setChipData( options );
  }, [ amenity ] );
  useEffect( () =>
  {
    let options = [];
    myAmenity.forEach( ( ele ) =>
    {
      options.push( amenity.filter( ( am ) => am.amenity === ele )[ 0 ].id );
    } );
    setAmenityQuery( options );
  }, [ myAmenity ] );

  const handleSelect = ( chipToSelect ) => () =>
  {
    if ( myAmenity.includes( chipToSelect.label ) )
    {
      setMyAmenity( ( am ) => am.filter( ( am ) => am !== chipToSelect.label ) );
    } else
    {
      setMyAmenity( [ ...myAmenity, chipToSelect.label ] );
    }
  };
  const handlePrice = ( myPrice ) =>
  {
    setPrice( myPrice );
    const result = myPrice.split( "-" );
    const [ min, max ] = result;
    setMinPrice( min );
    setMaxPrice( max );
  };
  // essential modal configuration ends
  // const [showModal, setShowModal] = useState(false);
  const [ modal1, setModal1 ] = useState( false );
  const [ scoreModal, setScoreModal ] = useState( false );
  const [ essentialModal, setEssentialsModal ] = useState( false );
  const [ assignAgentModal, setAssignAgentModal ] = useState( false );
  const [ communitiesModal, setCommunitiesModal ] = useState( false );
  // init score slider values
  const [ fromMetro, setFromMetro ] = useState( 389 );
  const [ fromShopping, setFromShopping ] = useState( 178 );
  const [ fromBeach, setFromBeach ] = useState( 363 );
  const [ fromSchool, setFromSchool ] = useState( 163 );
  const [ fromMosques, setFromMosques ] = useState( 298 );
  const [ fromSuperMarket, setSuperMarket ] = useState( 300 );
  const [ fromPharmacy, setFromPharmacy ] = useState( 100 );
  const [ frompark, setFrompark ] = useState( 415 );
  const [ metroCheck, setMetroCheck ] = useState( true );
  const [ shopCheck, setShopCheck ] = useState( true );
  const [ beachCheck, setBeachCheck ] = useState( false );
  const [ schoolCheck, setSchoolCheck ] = useState( false );
  const [ mosqueCheck, setMosqueCheck ] = useState( false );
  const [ superMarketCheck, setSuperMarketCheck ] = useState( false );
  const [ pharmacyCheck, setPharmacyCheck ] = useState( false );
  const [ parkCheck, setParkCheck ] = useState( false );
  // Search filters
  const [ price, setPrice ] = useState( "" );
  const [ minPrice, setMinPrice ] = useState( "" );
  const [ maxPrice, setMaxPrice ] = useState( "" );
  const [ beds, setBeds ] = useState( "" );
  const [ message, setMessage ] = useState( "" );
  const [ opened, setOpened ] = useState( false );

  // close get range modal and open range slider
  const toggleModal = () =>
  {
    setModal1( false );
    setShowModal( false );
    setScoreModal( true );
  };
  const toggleStartModal = () =>
  {
    setShowModal( false );
    setScoreModal( false );
    setShowModal( true );
  };
  // open or close range slider
  const toggleScoreModal = () =>
  {
    setScoreModal( !scoreModal );
    setEssentialsModal( !essentialModal );
  };

  // open or close Essentials
  const toggleEssentials = () =>
  {
    setEssentialsModal( !essentialModal );
    setCommunitiesModal( !communitiesModal );
  };

  // open or close communities
  const toggleCommunities = () =>
  {

    if ( selectedCommunities.length > 0 )
    {
      setCommunitiesModal( !communitiesModal );
      setAssignAgentModal( !assignAgentModal );
    } else
    {
      toast.warn( "Choose community" );
    }
  };

  const toggleAllModal = () =>
  {
    setModal1( false );
    setShowModal( false );
    setScoreModal( false );
    setEssentialsModal( false );
    setAssignAgentModal( false );
    setCommunitiesModal( false );
  };
  useEffect( () =>
  {
    if ( opened )
    {
      document.body.style.overflow = 'hidden';
    } else
    {
      document.body.style.overflow = 'auto';
    }

    // Cleanup when the component unmounts or modal closes
    return () =>
    {
      document.body.style.overflow = 'auto';
    };
  }, [ opened ] );
  return (
    <div className="container mx-auto 3xl:max-w-[1500px] lg:max-w-[930px] xl:max-w-[1200px] lg:mt-[6rem] md:mt-[17rem] 5xl:mt-[9rem] 6xl:mt-[4rem]">
      <div className="relative grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 mb-[1rem] 3xl:mt-[4rem]
     md:mb-[2rem] lg:mb-[5rem] gap-6  container mx-auto  ">
        <div className=" lg:col-span-2  shadow-customnavy rounded-[50px] 4xs:mx-4 3xl:mx-0 4xs:px-[1rem] lg:px-0 lg:pl-8 mt-8 md:mt-0">
          <div className="md:flex justify-between bg-gray-100 h-full rounded-[50px] rounded-tr-[50px]  lg:mx-0 md:pt-0">
            <div className=" my-6 lg:flex-1">
              <div className="my-4">
                <h3 className="md:text-4xl text-2xl font-bold text-left text-navy font-Lexend 4xs:pl-5">
                  Why Alba Homes
                </h3>
              </div>
              <div className="mb-4 text-left">
                <div >
                  <p className="md:text-lg text-navy font-Lexend pr-5 4xs:pl-5">
                    At Alba Homes, we believe that the cornerstone of any successful venture is built on a foundation of ethics,
                    integrity, and unwavering core values. Our mission is to transform the UAE Real Estate
                    experience by placing these principles at the heart of everything we do.
                  </p>
                  <p className="md:text-lg text-navy font-Lexend mt-3 pr-5 4xs:pl-5">
                    We are a tech-driven real estate agency dedicated to helping value-seeking investors,
                    landlords, and tenants achieve exceptional results.
                    Our guidance is not only grounded in solid market insights but also deeply rooted in our commitment to
                    integrity and ethical practices. We strive to create meaningful,
                    lasting relationships with our clients by providing transparent, honest, and insightful advice.
                  </p>    </div>


              </div>

              <div className="flex md:justify-start justify-start 4xs:pl-5">
                <Link href="/about-us" passHref>
                  <a className="px-10 bg-navy py-3 rounded-[50px] font-lexend font-bold text-[#FFDEAB] text-lg
                 hover:bg-[#DFC090]  hover:text-navy  relative block xl:text-lg  overflow-hidden">
                    Learn More
                  </a>
                </Link>
              </div>
            </div>
            <div className=" 4xs:hidden lg:block lg:flex-1 lg:h-full 
              rounded-r-[50px] w-[140vw]">

              <div className="relative lg:h-full w-full" style={ { height: '100%' } }>

                <picture>
                  <source
                    srcSet="/img/about-us-2.webp"
                    type="image/webp"
                  />
                  <img
                    alt="about-us"
                    src="/img/about-us-2.webp"
                    id="about"
                    className="object-cover object-center lg:h-full rounded-r-[50px]"
                  />
                </picture>

                <div className="absolute inset-0 bg-[#00000080] bg-opacity-50 flex justify-center items-center rounded-r-[50px] ">
                  <button onClick={ () => setOpened( true ) } className="z-10 text-white">
                    <img src="img/play-video.svg" className="w-20">
                    </img>
                  </button>
                </div>
                { opened && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#000000] bg-opacity-75 backdrop-blur-sm">
                    <div className="relative w-full max-w-4xl 2xl:max-w-7xl p-14 rounded-md">
                      <button
                        onClick={ () => setOpened( false ) }
                        className="absolute top-0 right-0  rounded-3xl "
                      >
                        <img src="img/cancel.svg" className="w-10 h-10"></img>
                      </button>
                      <div>
                        <video
                          width="100%"
                          height="100%"
                          src="videos/alba-homes.mp4"
                          title="Alba Homes"
                          controls
                          autoPlay
                          className="rounded-lg"
                        ></video>
                      </div>
                    </div>
                  </div>
                ) }
              </div>
            </div>

          </div>
        </div>
        <div className="lg:col-span-1 md:mx-10 lg:mx-0 4xs:mx-4">
          <div className="relative rounded-[50px] bg-soulmate bg-cover xl:h-full text-center 4xs:h-[25rem]">
            <div className="flex flex-col justify-center h-full px-6 pb-6  absolute z-10 top-2">
              <div className="text-white lg:pt-10 4xs:pt-5  ">
                <h2 className="xl:text-[32px] lg:text-3xl md:text-2xl text-[1.7rem] tracking-wide font-Lexend font-bold">
                  Find Your &nbsp;
                  <s className="decoration-white-400 font-Lexend font-bold text-[#FFDEAB]">Soulmate</s> Property...
                </h2>
                <p className="md:text-lg text-base 3xl:mt-8 mt-2 tracking-wide font-Lexend ">
                  Our algorithm matches your preference to the right space,
                  Identify your next space with a more intuitive search experience.
                </p>
              </div>
              <div className="flex flex-col mt-4 items-center justify-center">
                <button
                  id={ buttonName.getMatched }
                  className="bg-[#DFC090] font-Lexend font-bold md:py-4 py-2 xl:px-8 px-6 rounded-[50px]
                 text-navy hover:text-[#DFC090] xl:text-lg  lg:text-xl md:text-xl xl:my-4  hover:bg-navy relative block overflow-hidden text-base"
                  type="button"
                  onClick={ () => setShowModal( true ) }
                >
                  Get Matched to a Space
                </button>
              </div>
            </div>
            {/* Custom Gradient Overlay */ }
            <div className="absolute top-0 left-0 right-0 bottom-0 rounded-[50px] z-0 bg-gradient-to-b from-[#1E293B]  to-[#2A384A] opacity-75"></div>
          </div>
          {/* Get matach modal section start */ }
          { showModal ? (
            <>
              <div className=" rounded-2xl   fixed flex items-center justify-center inset-0 z-50 outline-none focus:outline-none ">
                <div className="rounded-2xl relative  mx-auto 2xl:w-[45vw] w-[85vw] bg-white ">
                  <div className="rounded-2xl border-0  shadow-lg relative flex flex-col w-full h-auto">

                    <div className="flex items-start justify-between p-11">
                      <h3 className="lg:text-3xl text-lg font-bold  text-navy font-Lexend ">
                        Find Your Community
                      </h3>
                      <div className="flex justify-end">
                        <button
                          className="text-lg font-bold 
                         relative block"
                          type="button"
                          onClick={ () => toggleAllModal() }
                        >
                          <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 1L1 13M1 1L13 13" stroke="#A6873B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </button>
                      </div>
                    </div>
                    <div className="flex-auto px-11">
                      <form
                        id={ formName.getMatched }
                        onSubmit={ ( e ) => handleSubmit( e ) }
                        method="POST"
                        className=" grid md:grid-cols-2 gap-4 sm:grid-cols-1">
                        <div className="flex flex-col items-left    rounded-full md:mb-1 sm:mb-4 ">
                          <label className="lg:text-xl pl-1 font-Lexend text-navy font-semibold mb-3">
                            Monthly Budget (AED)
                          </label>
                          <select
                            onChange={ ( e ) => handlePrice( e.target.value ) }
                            value={ price }
                            className=" text-xl cursor-pointer rounded-full border focus:border-0 bg-[#fff] shadow-customnavy
                             p-4 border-transparent focus:border-transparent  focus:ring-transparent"
                          >
                            <option defaultValue="">Select</option>
                            <option value="6000-8000">6000 - 8000</option>
                            <option value="8000-10000">8000 - 10000</option>
                            <option value="10000">10000+</option>
                          </select>
                        </div>

                        <div className="flex flex-col items-left bg-white rounded-[50px] md:mb-1 sm:mb-4 ">
                          <label className="lg:text-xl pl-1 font-Lexend text-navy font-semibold mb-3">Bedrooms</label>
                          <select
                            onChange={ ( e ) => setBeds( e.target.value ) }
                            value={ beds }
                            className="  cursor-pointer text-xl rounded-full  bg-[#fff] p-4 shadow-customnavy  border-transparent"
                          >
                            <option defaultValue="">Select</option>
                            { BedRooms &&
                              BedRooms.map( ( bed, index ) => (
                                <option key={ index } value={ bed }>
                                  { bed }
                                </option>
                              ) ) }
                          </select>
                        </div>

                      </form>
                    </div>
                    <div className="flex items-center justify-center p-6  rounded-b">
                      <button
                        className="bg-navy text-[#FFDEAB] lg:text-xl   font-bold  
                      text-base px-10 py-3 rounded-full  
                    relative block hover:bg-[#DFC090] hover:text-navy"
                        type="button"
                        onClick={ () => toggleModal() }
                      >
                        Continue
                      </button>
                    </div>

                    {/* communities section */ }
                    {/* {modal1 ? (<>
                      <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 xl:mx-20 mx-2'>
                          <Community />
                          <Community />
                          <Community />
                          <Community />
                          <Community />
                          <Community />
                          <Community />
                          <Community />
                      </div> 
                      
                      <div className="flex items-center justify-center py-6  rounded-b">
                        <button
                          className="bg-red-700 text-white lg:text-xl text-xs active:bg-emerald-600 font-bold  text-sm w-[50vw] lg:py-6 py-4 rounded-3xl button button--aylen  hover:bg-red-700  relative block tracking-widest overflow-hidden"
                          type="button" onClick={() => toggleModal()} >
                          Select the community(ies) of choice
                        </button>
                      </div>
                    
                    </>) : ''} */}
                  </div>
                </div>
              </div>
              <div className="opacity-80 fixed inset-0 z-40  bg-night "></div>
            </>
          ) : null }
          {/* Get matach modal section ends */ }
          {/* Get your walking score modal */ }
          { scoreModal ? (
            <>
              <div className=" lg:rounded-2xl flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="lg:rounded-2xl relative  lg:my-6 mx-auto xl:w-[65vw] 4xs:w-[100vw] lg:w-[85vw] bg-white ">
                  <div className="rounded-2xl border-0  shadow-lg relative flex flex-col w-full lg:h-auto h-screen  overflow-y-scroll">
                    <div className="flex items-start p-6 lg:mx-10">
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between items-center">
                          <h1 className="lg:text-4xl text-lg font-Lexend text-navy font-bold mt-6 mb-2">
                            What is important to you?
                          </h1>
                          <button className="text-lg font-bold relative block" type="button" onClick={ () => toggleAllModal() }>
                            <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13 1L1 13M1 1L13 13" stroke="#2A384A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div>
                        <h3 className="lg:text-2xl text-md font-Lexend text-navy">
                          Scale the distance from
                        </h3>
                      </div>
                    </div>
                    <div className=" p-6 flex-col  lg:mx-10 items-center mx-auto w-[90%]">
                      <div className="gap-2">
                        <div>
                          <p>Metro /Bus Stop </p>
                          <div className="flex gap-4">
                            <div className="flex items-center">
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                checked={ metroCheck }
                                onClick={ ( checked ) => setMetroCheck( ( c ) => !c ) }
                                className="focus:ring-red-500 h-4 w-4 text-black border-gray-300 rounded"
                              />
                            </div>
                            <IOSSlider
                              disabled={ !metroCheck }
                              aria-label="ios slider"
                              defaultValue={ fromMetro }
                              marks={ marks }
                              valueLabelDisplay={ metroCheck ? "on" : "off" }
                              min={ 0 }
                              max={ 1500 }
                              onChange={ ( _, v ) => setFromMetro( v ) }
                            />
                            { metroCheck ? (
                              <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                                { fromMetro }m
                              </span>
                            ) : (
                              <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                                { fromMetro }m
                              </span>
                            ) }
                          </div>
                        </div>
                        <div>
                          <p>Shopping </p>
                          <div className="flex gap-4">
                            <div className="flex items-center">
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                checked={ shopCheck }
                                onClick={ ( checked ) => setShopCheck( ( c ) => !c ) }
                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                              />
                            </div>
                            <IOSSlider
                              disabled={ !shopCheck }
                              aria-label="ios slider"
                              defaultValue={ fromShopping }
                              marks={ marks }
                              valueLabelDisplay={ shopCheck ? "on" : "off" }
                              min={ 0 }
                              max={ 1500 }
                              onChange={ ( _, v ) => setFromShopping( v ) }
                            />
                            { shopCheck ? (
                              <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                                { fromShopping }m
                              </span>
                            ) : (
                              <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                                { fromShopping }m
                              </span>
                            ) }
                          </div>
                        </div>
                        <div>
                          <p>Beach </p>
                          <div className="flex gap-4">
                            <div className="flex items-center">
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                checked={ beachCheck }
                                onClick={ ( checked ) => setBeachCheck( ( c ) => !c ) }
                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                              />
                            </div>
                            <IOSSlider
                              disabled={ !beachCheck }
                              aria-label="ios slider"
                              defaultValue={ fromBeach }
                              marks={ marks }
                              valueLabelDisplay={ beachCheck ? "on" : "off" }
                              min={ 0 }
                              max={ 1500 }
                              onChange={ ( _, v ) => setFromBeach( v ) }
                            />
                            { beachCheck ? (
                              <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                                { fromBeach }m
                              </span>
                            ) : (
                              <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                                { fromBeach }m
                              </span>
                            ) }
                          </div>
                        </div>
                        <div>
                          <p>School </p>
                          <div className="flex gap-4">
                            <div className="flex items-center">
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                checked={ schoolCheck }
                                onClick={ ( checked ) => setSchoolCheck( ( c ) => !c ) }
                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                              />
                            </div>
                            <IOSSlider
                              disabled={ !schoolCheck }
                              aria-label="ios slider"
                              defaultValue={ fromSchool }
                              marks={ marks }
                              valueLabelDisplay={ schoolCheck ? "on" : "off" }
                              min={ 0 }
                              max={ 1500 }
                              onChange={ ( _, v ) => setFromSchool( v ) }
                            />
                            { schoolCheck ? (
                              <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                                { fromSchool }m
                              </span>
                            ) : (
                              <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                                { fromSchool }m
                              </span>
                            ) }
                          </div>
                        </div>
                        <p>Mosques </p>
                        <div className="flex gap-4">
                          <div className="flex items-center">
                            <input
                              id="offers"
                              aria-describedby="offers-description"
                              name="offers"
                              type="checkbox"
                              checked={ mosqueCheck }
                              onClick={ ( checked ) => setMosqueCheck( ( c ) => !c ) }
                              className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                            />
                          </div>
                          <IOSSlider
                            disabled={ !mosqueCheck }
                            aria-label="ios slider"
                            defaultValue={ fromMosques }
                            marks={ marks }
                            valueLabelDisplay={ mosqueCheck ? "on" : "off" }
                            min={ 0 }
                            max={ 1500 }
                            onChange={ ( _, v ) => setFromMosques( v ) }
                          />
                          { mosqueCheck ? (
                            <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                              { fromMosques }m
                            </span>
                          ) : (
                            <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                              { fromMosques }m
                            </span>
                          ) }
                        </div>
                        <p>Supermarkets </p>
                        <div className="flex gap-4">
                          <div className="flex items-center">
                            <input
                              id="offers"
                              aria-describedby="offers-description"
                              name="offers"
                              type="checkbox"
                              checked={ superMarketCheck }
                              onClick={ ( checked ) =>
                                setSuperMarketCheck( ( c ) => !c )
                              }
                              className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                            />
                          </div>
                          <IOSSlider
                            disabled={ !superMarketCheck }
                            aria-label="ios slider"
                            defaultValue={ fromSuperMarket }
                            marks={ marks }
                            valueLabelDisplay={ superMarketCheck ? "on" : "off" }
                            min={ 0 }
                            max={ 1500 }
                            onChange={ ( _, v ) => setSuperMarket( v ) }
                          />
                          { superMarketCheck ? (
                            <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                              { fromSuperMarket }m
                            </span>
                          ) : (
                            <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                              { fromSuperMarket }m
                            </span>
                          ) }
                        </div>
                        <div>
                          <p>Pharmacy </p>
                          <div className="flex gap-4">
                            <div className="flex items-center">
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                checked={ pharmacyCheck }
                                onClick={ ( checked ) => setPharmacyCheck( ( c ) => !c ) }
                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                              />
                            </div>
                            <IOSSlider
                              disabled={ !pharmacyCheck }
                              aria-label="ios slider"
                              defaultValue={ fromPharmacy }
                              marks={ marks }
                              valueLabelDisplay={ pharmacyCheck ? "on" : "off" }
                              min={ 0 }
                              max={ 1500 }
                              onChange={ ( _, v ) => setFromPharmacy( v ) }
                            />
                            { pharmacyCheck ? (
                              <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                                { fromPharmacy }m
                              </span>
                            ) : (
                              <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                                { fromPharmacy }m
                              </span>
                            ) }
                          </div>
                        </div>
                        <p>Park </p>
                        <div className="flex gap-4">
                          <div className="flex items-center">
                            <input
                              id="offers"
                              aria-describedby="offers-description"
                              name="offers"
                              type="checkbox"
                              checked={ parkCheck }
                              onClick={ ( checked ) => setParkCheck( ( c ) => !c ) }
                              className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                            />
                          </div>
                          <IOSSlider
                            disabled={ !parkCheck }
                            aria-label="ios slider"
                            defaultValue={ frompark }
                            marks={ marks }
                            valueLabelDisplay={ parkCheck ? "on" : "off" }
                            min={ 0 }
                            max={ 1500 }
                            onChange={ ( _, v ) => setFrompark( v ) }
                          />
                          { parkCheck ? (
                            <span className="text-[#A6873B] font-lexend font-bold px-3 py-2 h-10 rounded-3xl">
                              { frompark }m
                            </span>
                          ) : (
                            <span className="bg-gray-300 text-white px-3 py-2 h-10 rounded-3xl">
                              { frompark }m
                            </span>
                          ) }
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center p-6  rounded-b">
                      <button
                        className="bg-[#E3EFFA] text-navy hover:bg-[#2A384A] hover:text-[#FFDEAB] lg:text-xl  font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 border border-navy mr-4 rounded-full   
                         relative font-lexend"
                        type="button"
                        onClick={ () => toggleStartModal() }
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        className="bg-[#2A384A] text-[#FFDEAB] hover:text-navy border border-navy hover:bg-[#E3EFFA] lg:text-xl font-lexend font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 rounded-full 
                         relative "
                        type="button"
                        onClick={ () => toggleScoreModal() }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-80 fixed inset-0 z-40 bg-navy"></div>
            </>
          ) : null }
          {/* Get your walking score modal ends */ }
          {/* Get your essentials modal start */ }
          { essentialModal ? (
            <>
              <div className="lg:rounded-2xl flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="lg:rounded-2xl relative  my-6 lg:mx-auto xl:w-[65vw] lg:w-[85vw] bg-white ">
                  <div className="rounded-2xl border-0  shadow-lg relative flex flex-col w-full 4xs:h-screen lg:h-auto  overflow-y-scroll">
                    <div className="flex items-start p-6 lg:mx-10">
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between items-center">
                          <h1 className="lg:text-4xl text-lg font-Lexend text-navy font-bold mt-6 mb-2">
                            What are your essentials

                          </h1>
                          <button className="text-lg font-bold relative block" type="button" onClick={ () => toggleAllModal() }>
                            <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13 1L1 13M1 1L13 13" stroke="#2A384A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div>
                        <h3 className="lg:text-2xl text-md font-Lexend text-navy">
                          What are you looking for with your space
                        </h3>
                      </div>
                    </div>

                    <div className=" p-6 flex-col   mx-auto w-[90%]  ">
                      <div className="flex justify-center flex-wrap gap-3">
                        { chipData &&
                          chipData.map(
                            ( data ) => (
                              <Chip
                                key={ data.key }
                                label={ data.label }
                                style={ {
                                  backgroundColor: myAmenity.includes( data.label )
                                    ? "#A6873B"
                                    : "",
                                  fontSize: "1rem",
                                  color: myAmenity.includes( data.label )
                                    ? "white"
                                    : "",
                                } }

                                onClick={ handleSelect( data ) }
                              />
                            )

                          ) }
                      </div>


                    </div>
                    <div className="flex items-center justify-center p-6  rounded-b">
                      <button
                        className="bg-[#E3EFFA] text-navy hover:bg-[#2A384A] hover:text-[#FFDEAB] lg:text-xl  font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 border border-navy mr-4 rounded-full   
                         relative font-lexend"
                        type="button"
                        onClick={ () => toggleScoreModal() }
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        className="bg-[#2A384A] text-[#FFDEAB] hover:text-navy border border-navy hover:bg-[#E3EFFA] lg:text-xl font-lexend font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 rounded-full 
                         relative"
                        type="button"
                        onClick={ () => toggleEssentials() }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-80 fixed inset-0 z-40  bg-navy "></div>
            </>
          ) : null }
          {/* Get your essentials modal ends */ }
          {/* select interested communities modal start */ }
          { communitiesModal ? (
            <>
              <div className=" lg:rounded-2xl  flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="lg:rounded-2xl relative  lg:my-6 lg:mx-auto w-[100vw] lg:w-[85vw] bg-white ">
                  <div className="rounded-2xl border-0  shadow-lg relative flex flex-col w-full max-h-screen 4xs:h-screen lg:h-auto overflow-y-scroll">

                    <div className="flex items-start justify-between p-11">
                      <h3 className="lg:text-3xl text-lg font-bold  text-navy font-Lexend ">
                        Which communities are you interested in?
                      </h3>
                      <div className="flex justify-end">
                        <button
                          className="text-lg font-bold 
                         relative block"
                          type="button"
                          onClick={ () => toggleAllModal() }
                        >
                          <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 1L1 13M1 1L13 13" stroke="#A6873B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center flex-wrap lg:mx-10 mx-auto  justify-center">

                      { communities?.map( ( community ) => (
                        <div
                          key={ community?.id }
                          className={ `"w-max py-2 px-4 border rounded-full cursor-pointer ${ selectedCommunities?.find(
                            ( comm ) => comm?.id === community?.id
                          )
                            ? "bg-[#A6873B] text-white"
                            : null
                            }` }
                          onClick={ () =>
                          {
                            const selected = selectedCommunities?.find(
                              ( comm ) => comm?.id === community?.id
                            );

                            selected
                              ? setSelectedCommunities(
                                selectedCommunities?.filter(
                                  ( comm ) => comm?.id !== community?.id
                                )
                              )
                              : setSelectedCommunities( ( prev ) => [
                                ...prev,
                                community,
                              ] );
                          } }
                        >
                          { community?.community }
                        </div>
                      ) ) }

                    </div>
                    <div className="flex items-center justify-center p-6  rounded-b">
                      <button
                        className="bg-[#E3EFFA] text-navy hover:bg-[#2A384A] hover:text-[#FFDEAB] lg:text-xl  font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 border border-navy mr-4 rounded-full   
                         relative font-lexend"
                        type="button"
                        onClick={ () => toggleEssentials() }
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        className="bg-[#2A384A] text-[#FFDEAB] hover:text-navy border border-navy hover:bg-[#E3EFFA] lg:text-xl font-lexend font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 rounded-full 
                         relative"
                        type="button"
                        onClick={ () => toggleCommunities() }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-80 fixed inset-0 z-40  bg-navy"></div>
            </>
          ) : null }
          {/* select interested communities modal ends */ }
          {/* Get your essentials modal start */ }
          { assignAgentModal ? (
            <>
              <form onSubmit={ ( e ) => handleSubmit( e ) }>
                <div className=" rounded-2xl flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none ">
                  <div className="rounded-2xl relative  my-6 mx-auto 2xl:w-[60vw] w-[85vw] bg-white ">
                    <div className="rounded-2xl border-0  shadow-lg relative flex flex-col w-full h-auto">
                      <div className="flex items-start p-6 lg:mx-10">
                        <div className="flex flex-col w-full">
                          <div className="flex justify-between items-center">
                            <h1 className="lg:text-3xl text-base font-Lexend text-navy font-bold mt-6 mb-2">
                              Our agent will assist you with your results

                            </h1>
                            <button className="text-lg font-bold relative block" type="button" onClick={ () => toggleAllModal() }>
                              <svg width="25" height="25" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L1 13M1 1L13 13" stroke="#2A384A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                          <h3 className="lg:text-2xl text-md font-Lexend text-navy">
                            Please share your contact information
                          </h3>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-y-6  mx-auto w-[70%] pt-6">
                        <>
                          <div>
                            <label
                              htmlFor="full-name"
                              className="sr-only"
                            >
                              Full name *
                            </label>
                            <input
                              required
                              minLength={ 2 }
                              onChange={ ( e ) =>
                              {
                                return setName( e.target.value );
                              }
                              }
                              onFocus={ () =>
                                handleErrors( "name", "" )
                              }
                              onBlur={ ( e ) =>
                              {
                                const { value } =
                                  e.target;

                                if (
                                  !value.trim() ||
                                  value.trim()
                                    .length < 2
                                )
                                {
                                  handleErrors(
                                    "name",
                                    !value.trim()
                                      ? "Name is required"
                                      : "Name too short"
                                  );
                                }
                              } }
                              value={ name }
                              type="text"
                              disabled={ loggedIn }
                              name="full-name"
                              id="full-name"
                              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-red-500 focus:border-red-500  rounded-full"
                              placeholder="Full name"
                            />
                            { errors?.name && (
                              <div className="text-sm text-red-500 ">
                                { errors.name }
                              </div>
                            ) }
                          </div>
                          <div>
                            <label
                              htmlFor="email"
                              className="sr-only"
                            >
                              Email *
                            </label>

                            <InputEmail
                              onInputChange={ ( txt ) =>
                                setEmail( txt )
                              }
                              onError={ ( err ) =>
                                handleErrors(
                                  "email",
                                  err
                                )
                              }
                              inputProps={ {
                                placeholder: "Email",
                                className:
                                  "!block !w-full shadow-sm !py-3 !px-4 placeholder-gray-500 focus:!ring-red-500 focus:!border-red-500  !rounded-full",
                                required: true,
                                value: email,
                                type: "email",
                                autoComplete: "email",
                              } }
                            />
                            { errors?.email && (
                              <div className="text-sm text-red-500 ">
                                { errors.email }
                              </div>
                            ) }

                          </div>
                          <div>
                            <label
                              htmlFor="phone"
                              className="sr-only"
                            >
                              Phone
                            </label>
                            <InputContact
                              onInputChange={ ( txt ) =>
                                setPhone( txt )
                              }
                              onSelectCountry={ (
                                country
                              ) =>
                                setSelectedCountry(
                                  country
                                )
                              }
                              country={ selectedCountry }
                              onError={ ( err ) =>
                                handleErrors(
                                  "phone",
                                  err
                                )
                              }
                              inputProps={ {
                                required: true,
                                autoComplete: "tel",
                                value: phone,
                                name: "phone",
                                id: "phone",
                                placeholder: "Phone",
                              } }
                              containerClasses={
                                "!bg-white"
                              }
                            />
                            { errors?.phone && (
                              <div className="text-sm text-red-500 ">
                                { errors.phone }
                              </div>
                            ) }

                          </div>

                          <div>
                            <AgreeToTerms
                              agreed={ isAgreed }
                              onClick={ () =>
                                setIsAgreed(
                                  ( prev ) => !prev
                                )
                              }
                            />

                          </div>
                          {/* <div className="flex 4xs:items-start 4xs:justify-start  lg:justify-center 2xl:mx-24">
                        <ReCAPTCHA
                            size="normal"
                            sitekey="6LfvfxsqAAAAAFT92Mg1RM5hF2LYjloy5lPZcphS"
                            onChange={onCaptchaChange}
                            ref={recaptcha}
                        />
                        
                    </div>
                    { errors?.captcha && (
                    <div className="flex 4xs:items-start 4xs:justify-start  lg:justify-center 2xl:mx-24 mt-3">
                            <div>
                            
                                <div className="text-red-500 text-sm">
                                    { errors?.captcha }
                                </div>
                            
                            </div>
                        
                    </div>) } */}
                        </>
                      </div>
                      <div className="flex flex-col">
                        { !loading ? (
                          <>
                            <div className="flex  items-center justify-center p-6  rounded-b">
                              <button
                                className="bg-[#E3EFFA] text-navy hover:bg-[#2A384A] hover:text-[#FFDEAB] lg:text-xl  font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 border border-navy mr-4 rounded-full   
                         relative font-lexend"
                                type="button"
                                onClick={ () => toggleCommunities() }
                              >
                                Back
                              </button>
                              &nbsp;
                              <button
                                id={ buttonName.submit }
                                type="submit"
                                className="bg-[#2A384A] text-[#FFDEAB] hover:text-navy border border-navy hover:bg-[#E3EFFA] lg:text-xl font-lexend font-bold 
                         text-sm xl:px-20  px-10 lg:py-4 py-3 rounded-full 
                         relative"
                              >
                                { submitting ? (
                                  <span className="flex items-center justify-center">
                                    <svg
                                      role="status"
                                      className="inline mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <span>
                                      Please wait
                                    </span>
                                  </span>
                                ) : (
                                  "Submit"
                                ) }
                              </button>
                            </div>
                            <div className="flex  items-center justify-center p-6 text-[#99700D] font-bold font-Lexend rounded-b">
                              <Link
                                href={ {
                                  pathname: "/find-the-properties",
                                  query: {
                                    community: selectedCommunities?.map(
                                      ( community ) => community?.id
                                    ),
                                    amenity: amenityQuery,
                                    ...( beds !== "" ? { beds } : {} ),
                                    ...( minPrice !== "" ? { minPrice: Number( minPrice ) * 12 } : {} ),
                                    ...( maxPrice !== "" ? { maxPrice: Number( maxPrice ) * 12 } : {} ),
                                  },
                                } }
                                className="text-[#99700D] pt-1"
                              >
                                Skip & show me the results
                              </Link>
                            </div>
                          </>
                        ) : (
                          <div className="p-6">
                            <Loader />
                          </div>
                        ) }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="opacity-80 fixed inset-0 z-40 backdrop-blur-[52px] bg-navy"></div>
            </>
          ) : null }
          {/* Get your essentials modal ends */ }
        </div>
      </div></div>
  );
};

export default GetMatch;
